import React from 'react';
import {
  Typography, Box, Card, CardMedia, Fade,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import { colors } from '../../constants';

export default function DetailBlocks() {
  const { t } = useTranslation();
  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  const inView2 = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  return (
    <Box>
      <Fade in={inView} timeout={1000}>
        <Box ref={ref} display="flex" flexDirection={{ xs: 'column', md: 'row' }} flex="1" sx={{ mb: 6, mt: 6 }}>
          <Box
            flex="1"
            sx={{
              mb: { md: 6 },
              mt: { md: 6 },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box sx={{ p: 2 }}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontWeight: 'bold', textAlign: 'left', fontFamily: 'Architects Daughter', color: colors.green,
                }}
              >
                Build With Confidence
              </Typography>
              <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'left' }}>
                Dedicated Development Team
              </Typography>
              <Box>
                <Typography variant="body1" gutterBottom sx={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}>
                  <CheckCircleIcon sx={{ color: colors.darkBlue, mr: 1 }} />
                  Full Time Developers
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}>
                  <CheckCircleIcon sx={{ color: colors.darkBlue, mr: 1 }} />
                  24/7 Access to the Team
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}>
                  <CheckCircleIcon sx={{ color: colors.darkBlue, mr: 1 }} />
                  Daily Stand Ups with Scrum
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box flex="1" sx={{ mb: { md: 6 }, mt: { md: 6 } }}>
            <Card sx={{ width: '100%' }}>
              <CardMedia
                component="img"
                height="140"
                image="/images/dd.webp"
                alt="Block 1"
              />
            </Card>
          </Box>
        </Box>
      </Fade>
      <Fade in={inView2.inView} timeout={1000}>
        <Box ref={inView2.ref} display="flex" flexDirection={{ xs: 'column-reverse', md: 'row' }} flex="1" sx={{ mb: 6, mt: 6 }}>
          <Box flex="1">
            <Card sx={{ width: '80%' }}>
              <CardMedia
                component="img"
                height="140"
                image="/images/otp2.webp"
                alt="Block 2"
              />
            </Card>
          </Box>
          <Box
            flex="1"
            sx={{
              mb: { md: 6 },
              mt: { md: 6 },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box sx={{ p: 2 }}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontWeight: 'bold', textAlign: 'left', fontFamily: 'Architects Daughter', color: colors.green,
                }}
              >
                Complete Transparency
              </Typography>
              <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'left' }}>
                Own the product
              </Typography>
              <Box>
                <Typography variant="body1" gutterBottom sx={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}>
                  <CheckCircleIcon sx={{ color: colors.darkBlue, mr: 1 }} />
                  1/1 Meetings with the Scum Master
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}>
                  <CheckCircleIcon sx={{ color: colors.darkBlue, mr: 1 }} />
                  Set Prorities for the team with the Backlog
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}>
                  <CheckCircleIcon sx={{ color: colors.darkBlue, mr: 1 }} />
                  Shift Goals at any time
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Box>
  );
}
