import React from 'react';
import { Box, Grid, Paper, Typography, Fade } from '@mui/material';
import { colors } from 'src/constants';
import { useInView } from 'react-intersection-observer';

const MyComponent = () => {
  const [ref, inView] = useInView({
    threshold: 0.4, // adjust this value to change when the fade-in effect occurs
    triggerOnce: true, // leave true to animate only once on load
  });
  const tiers = [
    {
      name: 'Standard',
      usPrice: '$12,000',
      price: '$6,000',
      description: 'At this tier, a team of this size would cost you $33,000 /mo to build in the USA. With Condor, you can build this team for 15% of the price',
      features: [
        { name: 'Scrum Master', included: true },
        { name: 'Designer', included: true },
        { name: 'Dedicated Developer 1', included: true },
        { name: 'Dedicated Developer 2', included: true },
        { name: 'Dedicated Developer 3', included: false },
        { name: 'Dedicated Developer 4', included:  false },
      ],
    },
    {
      name: 'Gold',
      usPrice: '$14,000',
      price: '$8,000',
      description: 'At this tier, a team of this size would cost you $41,666 /mo to build in the USA. With Condor, you can build this team for 14% of the price',
      features: [
        { name: 'Scrum Master', included: true },
        { name: 'Designer', included: true },
        { name: 'Dedicated Developer 1', included: true },
        { name: 'Dedicated Developer 2', included: true },
        { name: 'Dedicated Developer 3', included: true },
        { name: 'Dedicated Developer 4', included:  false },
      ],
    },
    {
      name: 'Platinum',
      usPrice: '$16,000',
      price: '$10,000',
      description: 'At this tier, a team of this size would cost you $50,000 /mo to build in the USA. With Condor, you can build this team for 13% of the price',
      features: [
        { name: 'Scrum Master', included: true },
        { name: 'Designer', included: true },
        { name: 'Dedicated Developer 1', included: true },
        { name: 'Dedicated Developer 2', included: true },
        { name: 'Dedicated Developer 3', included:  true },
        { name: 'Dedicated Developer 4', included:  true },
      ],
    },
  ];

  return (
    <Fade
      in={inView}
      timeout={1000}
    >
    <Grid ref={ref} container spacing={2} sx={{ 
      opacity: inView ? 1 : 0, // set the opacity based on the inView value
      paddingTop: '60px !important',
      paddingBottom: '60px',
      maxWidth: '1000px',
      margin: 'auto',
      width: { xs: '100%', md: 'inherit' }
      }}>
      {tiers.map((tier) => (
        <Grid item xs={12} md={4} key={tier.name} sx={{ padding: { xs: '0px' }}}>
          <Paper elevation={3}>
            <Box p={2}>
              <Typography variant="h6">{tier.name}</Typography>
              <Typography variant="h5" style={{ textDecoration: 'line-through', color: colors.darkBlue }}>{tier.usPrice} /mo</Typography>
              <Typography variant="h4">{tier.price} /mo</Typography>
              <Grid sx={{paddingTop: '20px'}} container spacing={1}>
                {tier.features.map((feature) => (
                  <Grid item xs={12} key={feature.name}>
                    <Grid container alignItems="center">
                      <Grid item xs={10}>
                        <Typography variant="body1" style={{ opacity: feature.included ? 1 : 0.4, textAlign: 'start' }}>{feature.name}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="body1" style={{ opacity: feature.included ? 1 : 0.4, color: colors.blue }}>{'\u2713'}</Typography> {/* Checkmark */}
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Typography sx={{paddingTop: '20px'}} variant="body1">{tier.description}</Typography>
            </Box>
          </Paper>
        </Grid>
      ))}
    </Grid>
    </Fade>
  );
};

export default MyComponent;
