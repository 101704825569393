import styled from 'styled-components';
import { Typography } from '@mui/material';
import { colors } from './constants';

export const Background = styled.div`
  background: white;
  width: 100%;
  height: 100%;
  // overflow: hidden;
`;
export const LogoText = styled(Typography)`
  font-family: 'Josefin Sans' !important;
  color: ${colors.green};
`;

export const HatLogo = styled.img`
  width: 100px;
  user-select: none;
  user-drag: none;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  background: white;
  user-select: none;
  user-drag: none;
  -webkit-user-select: none;
`;
