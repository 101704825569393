import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTracking } from 'react-tracking';

export default function ScrollTrack({ page }) {
  const { Track, trackEvent } = useTracking({ page });
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    let prevScrollY = window.pageYOffset;
    const handleScroll = () => {
      const currentScrollY = window.pageYOffset;
      if (currentScrollY > prevScrollY) {
        if (scrollDirection !== 'down') {
          setScrollDirection('down');
          trackEvent({ action: 'scroll', label: 'scroll-down' });
        }
      } else if (currentScrollY < prevScrollY) {
        if (scrollDirection !== 'up') {
          setScrollDirection('up');
          trackEvent({ action: 'scroll', label: 'scroll-up' });
        }
      }
      prevScrollY = currentScrollY;
    };
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrollDirection, trackEvent]);

  return (
    <Track />
  );
}

ScrollTrack.propTypes = {
  page: PropTypes.string.isRequired,
};
