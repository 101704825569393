/* eslint-disable */

import React from 'react';
import { Typography, Container, Paper } from '@mui/material';

function PrivacyPolicy() {
  return (
    <>
      <Container maxWidth="md">
        <Paper elevation={3} sx={{ padding: '2rem', textAlign: 'left'}}>
          <Typography variant='h4'>Privacy Policy</Typography>
          <Typography variant="h6" gutterBottom sx={{ paddingTop: '1rem' }}>
            1. Introduction
          </Typography>
          <Typography variant="body1" paragraph>
            Welcome to Condor Software Consultants LLC. We are committed to protecting the privacy and security of the personal information of our job applicants. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you apply for a job with us.
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ paddingTop: '1rem' }}>
            2. Information Collection
          </Typography>
          <Typography variant="body1" paragraph>
            When you apply for a job with Condor Software Consultants LLC, we may collect a variety of information from you, including but not limited to:
          </Typography>
          <Typography variant="body1">
            - Personal identification information: Name, address, email address, telephone number, and other contact information.
          </Typography>
          <Typography variant="body1">
            - Employment-related information: Resume/CV, cover letter, employment history, educational background, skills, references, and any other information you provide.
          </Typography>
          <Typography variant="body1">
            - Any other information you choose to provide during the application process.
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ paddingTop: '1rem' }}>
            3. Use of Information
          </Typography>
          <Typography variant="body1" paragraph>
            The information collected will be used solely for the purpose of processing your job application and assessing your suitability for the role. This includes:
          </Typography>
          <Typography variant="body1">
            - Communicating with you about the recruitment process.
          </Typography>
          <Typography variant="body1">
            - Evaluating your qualifications and skills.
          </Typography>
          <Typography variant="body1">
            - Performing background checks, if applicable.
          </Typography>
          <Typography variant="body1">
            - Complying with legal and regulatory requirements.
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ paddingTop: '1rem' }}>
            4. Information Sharing
          </Typography>
          <Typography variant="body1" paragraph>
            Condor Software Consultants LLC does not sell, rent, or lease your personal information to third parties. Your information may be shared with:
          </Typography>
          <Typography variant="body1">
            - Internal staff involved in the recruitment process.
          </Typography>
          <Typography variant="body1">
            - External service providers, if necessary, for background checks or other employment-related verifications, subject to confidentiality agreements.
          </Typography>
          <Typography variant="body1">
            - Legal or regulatory authorities, if required by law.
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ paddingTop: '1rem' }}>
            5. Data Security
          </Typography>
          <Typography variant="body1" paragraph>
            Condor Software Consultants LLC takes reasonable measures to protect the security of your personal information from unauthorized access, alteration, disclosure, or destruction.
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ paddingTop: '1rem' }}>
            6. Data Retention
          </Typography>
          <Typography variant="body1" paragraph>
            We retain your personal information for as long as necessary to fulfill the purposes outlined in this policy, unless a longer retention period is required or permitted by law.
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ paddingTop: '1rem' }}>
            7. Your Rights
          </Typography>
          <Typography variant="body1" paragraph>
            You have certain rights regarding your personal information, including the right to access, correct, or request deletion of your personal data held by Condor Software Consultants LLC.
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ paddingTop: '1rem' }}>
            8. Changes to This Policy
          </Typography>
          <Typography variant="body1" paragraph>
            Condor Software Consultants LLC reserves the right to modify this policy at any time. Any changes will be posted on our website with an updated effective date.
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ paddingTop: '1rem' }}>
            9. Contact Us
          </Typography>
          <Typography variant="body1">
            If you have any questions or concerns about this Privacy Policy or the handling of your personal information, please contact Condor Software Consultants LLC at contact@buildwithcondor.co
          </Typography>
        </Paper>
      </Container>
    </>
  );
}

export default PrivacyPolicy;
