import { Typography, Button } from '@mui/material';
import React, { useState } from 'react';

const TypographyShowMore = ({ children, maxLength=100 }) => {
  const [showMore, setShowMore] = useState(false);
  const truncatedText = children.length > maxLength ? `${children.slice(0, maxLength)}...` : children;

  const handleShowMore = () => {
    setShowMore(true);
  };

  return (
    <>
      <Typography>{showMore ? children : truncatedText}</Typography>
      {children.length > maxLength && !showMore && (
        <Button onClick={handleShowMore}>Show More</Button>
      )}
    </>
  );
};

export default TypographyShowMore;
