import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import RateReviewIcon from '@mui/icons-material/RateReview';
import { useInView } from 'react-intersection-observer';
import { colors } from '../../constants';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import AutoModeIcon from '@mui/icons-material/AutoMode';

const features = [
  {
    icon: <AutoModeIcon />,
    title: 'Software Automation',
    description: 'Interested in Automation? We have extensive experience in building automation software for a variety of industries.',
  },
  {
    icon: <AutoFixHighIcon />,
    title: 'AI/ChatGPT Specialist',
    description: 'Work with our AI/GPT specialists to build your next AI project. We have experience in building AI solutions for a variety of industries.',
  },
  {
    icon: <TipsAndUpdatesIcon />,
    title: 'Levarage Latest Tech',
    description: 'We stay up to date with the latest technologies to ensure that your project is built with the best tools available.',
  },

];

export default function FeatureBlock() {
  const { t } = useTranslation();
  const [ref, inView] = useInView({
    threshold: 0.2, // adjust this value to change when the fade-in effect occurs
    triggerOnce: true, // leave true to animate only once on load
  });

  return (
    <Box
      ref={ref}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        width: '100%',
        margin: 'auto',
        mt: 2,
        mb: 6,
      }}
    >
      {features.map((feature) => (
        <Box
          key={feature.title}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: { md: '30%', xs: '100%' },
            opacity: inView ? 1 : 0, // set the opacity based on the inView value
            transition: 'opacity 0.5s ease-in-out', // add a transition effect to the opacity change
          }}
        >
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '3em',
            height: '3em',
            background: colors.green,
            borderRadius: '50%',
            color: 'white',
            marginBottom: '20px',
            padding: '30px',
            mt: 3,
          }}
          >
            {feature.icon}
          </Box>
          <Typography sx={{ paddingBottom: '20px', fontWeight: 'bold' }} variant="h5">
            {feature.title}
          </Typography>
          <Typography sx={{ lineHeight: 1.5 }} variant="body1">
            {feature.description}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}
