// eslint-disable-next-line import/prefer-default-export
export const apiDispatch = (trackingData) => {
  try {
    fetch('/service/track', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(trackingData),
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
