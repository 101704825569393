// @ts-nocheck
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography, Grid, FormControl, FormHelperText,
  Button, Box, Divider, Chip,
} from '@mui/material';

import { useTracking } from 'react-tracking';
import DetailBlocks from '../../partials/DetailBlocks';
import FeatureBlock from '../../partials/Features';
import { colors } from '../../constants';
import Footer from '../../partials/Footer';
import Navbar from '../../partials/Navbar';
import ScrollTrack from '../../partials/ScrollTrack';
import PricingBlocks from '../../partials/PricingBlocks';
import TypographyShowMore from 'src/partials/TypographyShowMore';

function Main() {
  const [betaClick, setBetaClick] = useState(false);
  const [emailSent] = useState(false);
  const [internalError] = useState(false);
  const { t } = useTranslation();
  const { trackEvent } = useTracking({ page: 'home' });
  const calendlyLink = 'https://calendly.com/asrix1244/30-min-condor-consultation-call'

  return (
    <>
      <ScrollTrack page="home" />
      <Navbar showDownloadButton={false} />
      <Box sx={{
        width: { xs: '90%', md: '70%' }, maxWidth: '1200px', margin: 'auto', mt: 10,
      }}
      >
        <Grid container>
          <Grid container xs={12}>
            <Grid
              xs={12}
              md={6}
              container
              sx={{
                textAlign: 'left', display: 'flex', alignItems: 'baseline', height: 'auto',
              }}
            >
              <Grid xs={12} item>
                <Typography variant="h1" sx={{ fontSize: { xs: '60px', md: '80px' } }}>
                  Your Vision <br />
                  <span style={{ color: colors.green }}>
                    Built With Condor
                  </span>
                </Typography>
                <TypographyShowMore maxLength={300}>
                  Welcome to Condor Consultants, your premier destination for cutting-edge, cost-effective custom software solutions. At the heart of our approach is the Agile Scrum methodology, expertly managed by our U.S.-based Scrum Masters and brought to life by our talented team of developers from Latin America. We specialize in transforming your vision into reality while ensuring significant cost savings. Our unique blend of skilled, cross-cultural teamwork and client-centric development ensures that every project is not just completed, but meticulously crafted to exceed expectations. Discover how our innovative strategies and global expertise can accelerate your business growth, delivering high-quality software tailored to your needs.
                </TypographyShowMore>

                {betaClick && !emailSent && !internalError && (
                <Box
                  sx={{
                    display: { xs: 'block', md: 'flex' },
                    alignItems: 'flex-start',
                    '& > :not(style)': { m: 1 },
                    marginTop: '60px',
                    textAlign: 'center',
                    height: '100px',
                  }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    onClick={() => {
                      window.open('https://blog.condor.co/try-today', '_blank');
                      // @ts-ignore
                      trackEvent({ action: 'click', label: 'try-today' });
                    }}
                    sx={{
                      width: { xs: '100%', md: 'inherit' },
                      marginLeft: { xs: '0px !important', md: '8px !important' },
                      marginRight: { xs: '0px !important', md: '8px !important' },
                    }}
                  >
                    {t('downloadChromeButton')}
                    <img style={{ width: '30px', paddingLeft: '2px' }} src="/chrome.png" alt="chrome" />
                  </Button>

                  <Button
                    variant="contained"
                    size="large"
                    disabled
                    sx={{
                      width: { xs: '100%', md: 'inherit' },
                      marginLeft: { xs: '0px !important', md: '8px !important' },
                      marginRight: { xs: '0px !important', md: '8px !important' },
                    }}
                  >
                    {t('downloadSafariButton')}
                    <img style={{ width: '30px', paddingLeft: '2px' }} src="/safari.png" alt="safari" />
                  </Button>
                </Box>

                )}
                {!betaClick && !emailSent && !internalError && (
                <FormControl sx={{
                  marginTop: '60px', textAlign: 'left', height: '100px', width: { xs: '100%', md: '100%' },
                }}
                >
                  <Button
                    sx={{
                      width: { xs: '100%', md: 'fit-content' },
                    }}
                    onClick={() => {
                      trackEvent({ action: 'click', label: 'join-beta' });
                      window.open(calendlyLink, '_blank');
                    }}
                    variant="contained"
                    size="large"
                  >
                    Book Consultation
                  </Button>
                  <FormHelperText id="my-helper-text">
                    All our Consultations are free
                  </FormHelperText>
                </FormControl>
                )}

                {emailSent && (
                <Box sx={{ height: '100px' }}>
                  <Typography variant="h6" sx={{ marginTop: '60px', width: { xs: '100%', md: 'inherit' } }}>
                    {t('onWaitlistMessage')}
                  </Typography>

                </Box>
                )}

                {internalError && (
                <Box sx={{ height: '100px' }}>
                  <Typography variant="h6" sx={{ marginTop: '60px', width: { xs: '100%', md: 'inherit' } }}>
                    {t('internalErrorMessage')}
                  </Typography>
                </Box>
                )}

              </Grid>
            </Grid>
            <Grid sx={{ paddingTop: '20px', height: 'auto' }} xs={12} md={6} item>
              <img width={'500px'} src="/images/logo.webp" alt="Graphic" />
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ mt: 6, mb: 3 }}>
          <Chip label="You're In Control" color="primary" sx={{ mb: 2, color: 'white', fontSize: '1em' }} />
          <Typography sx={{ mb: 3 }} variant="h3">
            Using a Agile Development Methodoloy
          </Typography>
          <Typography>
            With the direction of our U.S.-based Scrum Masters, you are in control of your project every step of the way. Our Scrum Masters are experts in Agile Scrum methodology, ensuring that your project is completed on time and on budget. Attend stand ups, give feedback, and watch your vision come to life.
          </Typography>
        </Box>
        <FeatureBlock />
        <Divider />
        <DetailBlocks />
        <Divider />
        <PricingBlocks />
        <Divider sx={{ mt: { x: 1, md: 8 }, mb: { x: 1, md: 6 } }} />
        <Box sx={{ mt: { xs: 1, md: 6 }, mb: { xs: 1, md: 6 }, pt: '60px' }}>
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}> 
            Book a Free Consultation Today
          </Typography>
          <Button
            onClick={() => {
              trackEvent({ action: 'click', label: 'try-now-bottom-button' });
              window.open(calendlyLink, '_blank');
            }}
            size="large"
            variant="contained"
          >
            Schedule Now
          </Button>
        </Box>

        <Footer />
      </Box>
    </>
  );
}

export default Main;
