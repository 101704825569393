import {
  ThemeProvider,
} from '@mui/material';
import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import { theme } from './theme';
import {
  Background,
} from './styled';
import PrivacyPage from './partials/PrivacyPage'; // Updated import path
import Main from './pages/Home';
import CalculatorPage from './pages/Calculator';
import CalculatorProvider from './context/Calculator';
import ScrollToTopButton from './partials/ScrollTop';
// eslint-disable-next-line import/extensions
import './il8n.js';
import LogRocket from 'logrocket';
LogRocket.init('qklaum/condor-landing');

import { apiDispatch } from './api/tracker';

function App() {
  const { Track } = useTracking({}, { dispatch: apiDispatch });

  return (
    <ThemeProvider theme={theme.main}>
      <Track>
        <Background className="App">
          <Router>
            <React.StrictMode>
              <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/candidate-privacy" element={<PrivacyPage />} />
                <Route path="/test" element={<PrivacyPage />} />
              </Routes>
            </React.StrictMode>
          </Router>
        </Background>
        <ScrollToTopButton />
      </Track>
    </ThemeProvider>
  );
}

export default App;
