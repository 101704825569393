import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, InputLabel, Select, MenuItem,
} from '@mui/material';

function FilterableSelect({
  label, items, value, onChange, name, sx, onSearchChange,
}) {
  const [searchText, setSearchText] = useState('');

  const handleKeyUp = (e) => {
    if (e.key !== 'Backspace' && e.key !== 'Enter' && e.key !== 'Tab' && e.key !== 'Shift' && e.key !== 'ArrowDown' && e.key !== 'ArrowUp' && e.key !== 'Escape') {
      const newSearchText = searchText + e.key;
      setSearchText(newSearchText);

      // Trigger the optional callback if provided
      if (onSearchChange) {
        onSearchChange(newSearchText);
      }
    }
    if (e.key === 'Backspace') {
      const newSearchText = searchText.slice(0, -1);
      setSearchText(newSearchText);

      // Trigger the optional callback if provided
      if (onSearchChange) {
        onSearchChange(newSearchText);
      }
    }
  };

  return (
    <FormControl fullWidth sx={sx} onKeyUp={handleKeyUp}>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        labelId={`${name}-label`}
        label={label}
        variant="outlined"
        fullWidth
        name={name}
        value={value}
        onChange={onChange}
        onOpen={() => setSearchText('')}
        onClose={() => setSearchText('')}
      >
        {(searchText && (
        <MenuItem value={searchText} disabled>
          {searchText}
        </MenuItem>
        )) || (
        <MenuItem value={searchText} disabled>
          Type to filter
        </MenuItem>
        )}
        {items.map((item) => (
          <MenuItem
            disabled={item.disabled || false}
            key={item.value}
            value={item.value}
          >
            {item.label}
          </MenuItem>
        ))}

      </Select>
    </FormControl>
  );
}

FilterableSelect.propTypes = {
  label: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
  })).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  sx: PropTypes.shape({}),
  onSearchChange: PropTypes.func,
};

FilterableSelect.defaultProps = {
  sx: {},
  onSearchChange: null,
};

export default FilterableSelect;
