import { ApolloClient, InMemoryCache } from '@apollo/client';
import { DEFAULT_ENDPOINT } from './constants';

export const ApiClient = new ApolloClient({
  uri: `${DEFAULT_ENDPOINT}/graphql`,
  cache: new InMemoryCache(),
  credentials: 'include',
});

export default ApiClient;
