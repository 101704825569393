import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar, Toolbar, Box, Button, LinearProgress, IconButton,
  Menu, MenuItem, ListItemIcon, ListItemText, Divider,
  useMediaQuery,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import { Menu as MenuIcon } from '@mui/icons-material';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import CalculateIcon from '@mui/icons-material/Calculate';
import ExtensionIcon from '@mui/icons-material/Extension';
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';
import { LogoContainer } from '../../styled';

function Navbar({
  showLogo = true,
  showCalculatorButton = true,
  showDownloadButton = true,
  loading = false,
}) {
  const { i18n, t } = useTranslation(); // Add this line to use i18next
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [anchorEl, setAnchorEl] = useState(null);
  const [languageAnchorEl, setLanguageAnchorEl] = useState(null);
  const languageMenuButtonRef = useRef(null); // Create a ref for the language menu button
  const { trackEvent } = useTracking(); // Add this line to use react-tracking

  // This effect runs once on component mount
  useEffect(() => {
    // Check if it's the user's first visit
    if (!localStorage.getItem('hasVisited')) {
      // If so, "click" the language menu button by setting its ref
      languageMenuButtonRef.current.click();
      // Record in local storage that the user has visited
      localStorage.setItem('hasVisited', 'true');
    }
  }, []);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageMenuOpen = (event) => {
    setLanguageAnchorEl(event.currentTarget);
  };

  const handleLanguageMenuClose = () => {
    setLanguageAnchorEl(null);
  };

  const handleLanguageChange = (event) => {
    const language = event.currentTarget.getAttribute('value');
    i18n.changeLanguage(language);
    handleLanguageMenuClose();
    trackEvent({ // Add this line to track the event
      action: 'click',
      label: `language_change_${language}`,
    });
  };

  const handleCalculatorButtonClick = () => {
    // navigate('/calculator');
    // trackEvent({ // Add this line to track the event
    //   action: 'click',
    //   label: 'calculator_button_click',
    // });
  };

  const handleExtensionButtonClick = () => {
    navigate('/');
    trackEvent({ // Add this line to track the event
      action: 'click',
      label: 'extension_button_click',
    });
  };

  return (
    <Box sx={{
      position: 'fixed',
      width: '100%',
      zIndex: 100,
      backgroundColor: 'white',
      top: 0,
    }}
    >
      <Box>
        <LinearProgress sx={{ visibility: loading ? 'inherit' : 'hidden' }} />
      </Box>
      <AppBar
        elevation={0}
        position="static"
        sx={{
          padding: 0, width: { xs: '90%', md: '100%' }, margin: 'auto', background: 'white',
        }}
      >
        <Toolbar sx={{
          display: 'flex', alignItems: 'center', paddingLeft: '0 !important', paddingRight: '0 !important',
        }}
        >

          <Box sx={{ flexGrow: 1, height: { xs: 'auto' }, border: '2px solid transparent' }}>
            {showLogo && (
              <LogoContainer onClick={() => navigate('/')} />
            )}
          </Box>

          {isMobile ? (
            <>
              <Box sx={{ marginLeft: 'auto' }}>
                <Button
                  color="inherit"
                  ref={languageMenuButtonRef}
                  aria-controls="language-menu"
                  aria-haspopup="true"
                  onClick={handleLanguageMenuOpen}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ marginRight: '8px' }}>
                      {i18n.language.toUpperCase()}
                    </Box>
                    <Box>
                      <LanguageIcon />
                    </Box>
                  </Box>
                </Button>
                <Menu
                  id="language-menu"
                  anchorEl={languageAnchorEl}
                  open={Boolean(languageAnchorEl)}
                  onClose={handleLanguageMenuClose}
                >
                  <MenuItem value="en" onClick={handleLanguageChange}>
                    <ListItemText primary={t('navbar.english')} />
                  </MenuItem>
                  <MenuItem disabled value="es" onClick={handleLanguageChange}>
                    <ListItemText primary={t('navbar.spanish')} />
                  </MenuItem>
                </Menu>
              </Box>
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={handleMenuOpen}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                {/* <MenuItem onClick={handleCalculatorButtonClick}>
                  <ListItemIcon>
                    <CalculateIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('navbar.calculator')} />
                </MenuItem>
                <MenuItem onClick={handleExtensionButtonClick}>
                  <ListItemIcon>
                    <ExtensionIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('navbar.extension')} />
                </MenuItem>
                <Divider /> */}
                <MenuItem disabled onClick={() => {
                  trackEvent({
                    action: 'click',
                    label: 'blog_button_click',
                  });
                  window.location.href = 'https://condor.co/blog';
                }}
                >
                  <ListItemIcon>
                    <CollectionsBookmarkIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('navbar.blog')} />
                </MenuItem>
              </Menu>
            </>
          ) : (
            <>
              <Box sx={{ marginLeft: 'auto' }}>
                <Button
                  color="inherit"
                  ref={languageMenuButtonRef}
                  aria-controls="language-menu"
                  aria-haspopup="true"
                  onClick={handleLanguageMenuOpen}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ marginRight: '8px' }}>
                      {i18n.language.toUpperCase()}
                    </Box>
                    <Box>
                      <LanguageIcon />
                    </Box>
                  </Box>
                </Button>
                <Menu
                  id="language-menu"
                  anchorEl={languageAnchorEl}
                  open={Boolean(languageAnchorEl)}
                  onClose={handleLanguageMenuClose}
                >
                  <MenuItem value="en" onClick={handleLanguageChange}>
                    <ListItemText primary={t('navbar.english')} />
                  </MenuItem>
                  <MenuItem disabled value="es" onClick={handleLanguageChange}>
                    <ListItemText primary={t('navbar.spanish')} />
                  </MenuItem>
                </Menu>
              </Box>
            </>
          )}

        </Toolbar>
      </AppBar>
    </Box>
  );
}

Navbar.propTypes = {
  showLogo: PropTypes.bool.isRequired,
  showCalculatorButton: PropTypes.bool.isRequired,
  showDownloadButton: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default Navbar;
